import React, { useEffect, useState } from "react";
import {
  Dialog,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
  Link,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../redux/auth/authActions";

const OTPLESS_APP_ID = "4JVE0T8RHXIRLR44DMNR";

const LoginPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { hash, pathname } = useLocation();

  const [open, setOpen] = useState(true);
  const [openOtp, setOpenOtp] = useState(false);
  const [username, setUsername] = useState("");
  const [location, setLocation] = useState("");
  const [pincode, setPincode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [resToken, setResToken] = useState("");
  const { isAuthenticated, user } = useSelector((state) => state.auth);

  const handleClose = () => {
    setOpen(false);
    navigate(pathname);
  };

  const handleOtpVerification = () => {
    if (otp.trim() === "") {
      alert("Please enter the OTP");
      return;
    }

    window.OTPlessSignin.verify({
      channel: "PHONE",
      phone: phoneNumber,
      otp: otp,
      countryCode: "+91",
    });
  };

  const handleLogin = async () => {
    if (!username || !location || !pincode || !phoneNumber) {
      alert("All fields are required: Name, Mobile, Location, Pincode");
      return;
    }

    try {
      const res = await axios.post(
        "https://backend-janta-rides.vercel.app/api/webUser/send-otp",
        {
          phoneNumber: `+91${phoneNumber}`,
          name: username,
          location,
          pincode,
        }
      );

      const result = res?.data;
      if (result?.success) {
        window.OTPlessSignin.initiate({
          channel: "PHONE",
          phone: phoneNumber,
          countryCode: "+91",
        });
        setOpen(false);
        setOpenOtp(true);
      }
    } catch (err) {
      console.error("Error during handleLogin:", err);
    }
  };

  // const verifyToken = async (token) => {
  //   try {
  //     const res = await axios.post(
  //       "https://backend-janta-rides.vercel.app/api/webUser/verify-token",
  //       { token }
  //     );
  //     const result = res?.data;
  //     // console.log("Token verification result:", result);
  //   } catch (error) {
  //     console.error("Token verification error:", error);
  //   }
  // };

  const verifyUserUpdate = async () => {
    try {
      const res = await axios.post(
        "https://backend-janta-rides.vercel.app/api/webUser/verify-otp",
        {
          phoneNumber: `+91${phoneNumber}`,
          name: username,
          location,
          pincode,
        }
      );

      const result = res?.data;
      if (
        result?.success &&
        result?.message === "OTP and User verified successfully"
      ) {
        dispatch(login(result));
        setOpen(false);
        setOpenOtp(false);
        navigate(pathname);
        return result;
      }
    } catch (error) {
      console.error("User update verification error:", error);
    }
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.id = "otpless-sdk";
    script.src = "https://otpless.com/v4/headless.js";
    script.setAttribute("data-appid", OTPLESS_APP_ID);

    script.onload = () => {
      if (window.OTPless) {
        const callback = (eventCallback) => {
          const handleTokenValidation = async (token) => {
            try {
              const apiHeaders = {
                clientId: "B29U7LFP8SJEQD3UAN43J8Z3HMIIKMRA",
                clientSecret: "yql2015yus892ullst6lnuogefu7zt12",
                "Content-Type": "application/json",
              };

              const response = await fetch(
                "https://user-auth.otpless.app/auth/v1/validate/token",
                {
                  method: "POST",
                  headers: apiHeaders,
                  body: JSON.stringify({ token }),
                }
              );

              if (!response.ok) {
                throw new Error(
                  `Token validation failed with status: ${response.status}`
                );
              }

              return response.json();
            } catch (error) {
              console.error("Token validation error:", error);
              throw error;
            }
          };

          const EVENTS_MAP = {
            ONETAP: async () => {
              const { response } = eventCallback;
              if (response?.token) {
                try {
                  const validatedResponse = await handleTokenValidation(
                    response.token
                  );
                  if (validatedResponse?.token) {
                    setResToken(validatedResponse?.token);
                  }
                } catch (error) {
                  console.error("Error during ONETAP process:", error);
                }
              }
            },
            OTP_AUTO_READ: () => {
              const {
                response: { otp },
              } = eventCallback;
              setOtp(otp);
            },
            FAILED: () => {
              console.error("Authentication Failed:", eventCallback);
            },
            FALLBACK_TRIGGERED: () => {
              console.warn("Fallback Triggered:", eventCallback);
            },
          };

          const eventType = eventCallback.responseType;
          if (eventType in EVENTS_MAP) {
            EVENTS_MAP[eventType]();
          }
        };

        window.OTPlessSignin = new window.OTPless(callback);
      } else {
        console.error("OTPless SDK failed to load.");
      }
    };

    script.onerror = () => {
      console.error("Failed to load OTPLESS SDK.");
    };

    document.head.appendChild(script);
  }, []);

  useEffect(() => {
    if (resToken !== "") {
      verifyUserUpdate();
    }
  }, [resToken]);
  useEffect(() => {
    if (isAuthenticated) {
      if (user && user?.role === "user") {
        navigate("/");
      }
    }
  }, [isAuthenticated, navigate, user?.role]);

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        backdropFilter: "blur(5px)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Dialog
        open={open}
        PaperProps={{
          sx: {
            width: 400,
            borderRadius: 2,
            padding: 3,
          },
        }}
      >
        <Box>
          <IconButton
            onClick={handleClose}
            sx={{ position: "absolute", top: 8, right: 8 }}
          >
            <CloseIcon />
          </IconButton>

          <Typography
            variant="h5"
            component="h1"
            align="center"
            sx={{ fontWeight: "bold", color: "#000" }}
          >
            Janta Rides
          </Typography>
          <Typography variant="subtitle1" align="center" sx={{ mb: 2 }}>
            Login for a seamless experience
          </Typography>

          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <Typography sx={{ mr: 2 }}>Name</Typography>
            <TextField
              onChange={(e) => setUsername(e.target.value)}
              fullWidth
              placeholder="Enter Fullname*"
              variant="outlined"
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "8px",
                },
              }}
            />
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <Typography sx={{ mr: 4 }}>{`+91    `}</Typography>
            <TextField
              onChange={(e) => setPhoneNumber(e.target.value)}
              fullWidth
              placeholder="Enter Mobile Number*"
              variant="outlined"
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "8px",
                },
              }}
            />
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <Typography sx={{ mr: 1 }}>Pincode</Typography>
            <TextField
              onChange={(e) => setPincode(e.target.value)}
              fullWidth
              placeholder="Enter area pincode*"
              variant="outlined"
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "8px",
                },
              }}
            />
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <Typography sx={{ mr: 1 }}>Location</Typography>
            <TextField
              onChange={(e) => setLocation(e.target.value)}
              fullWidth
              placeholder="Enter Location*"
              variant="outlined"
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "8px",
                },
              }}
            />
          </Box>

          <FormControlLabel
            control={<Checkbox defaultChecked />}
            label={
              <Typography variant="body2">
                I Agree to Terms and Conditions{" "}
                <Link href="#" underline="hover">
                  T&C's Privacy Policy
                </Link>
              </Typography>
            }
          />

          <Button
            fullWidth
            variant="contained"
            onClick={handleLogin}
            sx={{
              backgroundColor: "#007BFF",
              color: "#fff",
              borderRadius: "8px",
              padding: "10px",
              textTransform: "none",
              fontWeight: "bold",
              mb: 2,
              "&:hover": {
                backgroundColor: "#0056b3",
              },
            }}
          >
            Login with OTP
          </Button>
        </Box>
      </Dialog>
      <Dialog
        open={openOtp}
        PaperProps={{
          sx: {
            width: 400,
            borderRadius: 2,
            padding: 3,
          },
        }}
      >
        <Box>
          <IconButton
            onClick={handleClose}
            sx={{ position: "absolute", top: 8, right: 8 }}
          >
            <CloseIcon />
          </IconButton>

          <Typography
            variant="h5"
            component="h1"
            align="center"
            sx={{ fontWeight: "bold", color: "teal", my: 1 }}
          >
            Verify your OTP
          </Typography>

          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <TextField
              size="small"
              onChange={(e) => setOtp(e.target.value)}
              fullWidth
              placeholder="Enter OTP"
              variant="outlined"
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "8px",
                },
              }}
            />
          </Box>

          <Button
            fullWidth
            variant="contained"
            onClick={handleOtpVerification}
            sx={{
              backgroundColor: "#007BFF",
              color: "#fff",
              borderRadius: "8px",
              padding: "10px",
              textTransform: "none",
              fontWeight: "bold",
              mb: 2,
              "&:hover": {
                backgroundColor: "#0056b3",
              },
            }}
          >
            Verify OTP
          </Button>
        </Box>
      </Dialog>
    </Box>
  );
};

export default LoginPage;

// import React from "react";
// import {
//   Box,
//   Button,
//   TextField,
//   Checkbox,
//   FormControlLabel,
//   Typography,
//   Link,
//   Divider,
//   IconButton,
//   InputAdornment,
// } from "@mui/material";
// import GoogleIcon from "@mui/icons-material/Google";

// const LoginPage = () => {
//   return (
//     <Box
//       sx={{
//         width: "400px",
//         margin: "auto",
//         padding: "24px",
//         borderRadius: "12px",
//         boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
//         backgroundColor: "white",
//       }}
//     >
//       <Box display="flex" alignItems="center" mb={2}>
//         <img
//           src="https://example.com/jantarides-logo.png"
//           alt="jantarides"
//           style={{ width: "120px" }}
//         />
//       </Box>
//       <Typography variant="h6" mb={1}>
//         Welcome
//       </Typography>
//       <Typography variant="subtitle1" color="textSecondary" mb={2}>
//         Login for a seamless experience
//       </Typography>
//       <TextField
//         fullWidth
//         label="Enter Mobile Number*"
//         variant="outlined"
//         InputProps={{
//           startAdornment: (
//             <InputAdornment position="start">
//               <Typography variant="subtitle1">+91</Typography>
//             </InputAdornment>
//           ),
//         }}
//       />
//       <FormControlLabel
//         control={<Checkbox />}
//         label={
//           <Typography variant="body2">
//             I Agree to{" "}
//             <Link href="#" underline="hover">
//               Terms and Conditions
//             </Link>{" "}
//             &{" "}
//             <Link href="#" underline="hover">
//               T&C's Privacy Policy
//             </Link>
//           </Typography>
//         }
//         sx={{ marginTop: "16px", marginBottom: "16px" }}
//       />
//       <Button
//         fullWidth
//         variant="contained"
//         color="primary"
//         size="large"
//         sx={{ padding: "12px 0", marginBottom: "16px" }}
//       >
//         Login with OTP
//       </Button>
//       <Divider>
//         <Typography variant="body2" color="textSecondary">
//           Or Login Using
//         </Typography>
//       </Divider>
//       <Button
//         fullWidth
//         variant="outlined"
//         startIcon={<GoogleIcon />}
//         sx={{
//           padding: "10px 0",
//           marginTop: "16px",
//           borderColor: "#4285F4",
//           color: "#4285F4",
//         }}
//       >
//         Google
//       </Button>
//       <Button
//         variant="text"
//         color="primary"
//         fullWidth
//         sx={{ marginTop: "16px" }}
//       >
//         Skip
//       </Button>
//     </Box>
//   );
// };

// export default LoginPage;

// // import React from "react";
// // import {
// //   Box,
// //   Button,
// //   Checkbox,
// //   FormControlLabel,
// //   TextField,
// //   Typography,
// //   Link,
// //   Divider,
// // } from "@mui/material";
// // import GoogleIcon from "@mui/icons-material/Google"; // Assuming Google icon is used

// // const LoginPage = () => {
// //   return (
// //     <Box
// //       sx={{
// //         width: 400,
// //         margin: "auto",
// //         padding: 3,
// //         borderRadius: 2,
// //         boxShadow: 3,
// //         backgroundColor: "white",
// //       }}
// //     >
// //       <Typography
// //         variant="h5"
// //         component="h1"
// //         align="center"
// //         sx={{ fontWeight: "bold", color: "#000" }}
// //       >
// //         Welcome
// //       </Typography>
// //       <Typography variant="subtitle1" align="center" sx={{ mb: 2 }}>
// //         Login for a seamless experience
// //       </Typography>

// //       <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
// //         <Typography sx={{ mr: 1 }}>+91</Typography>
// //         <TextField
// //           fullWidth
// //           placeholder="Enter Mobile Number*"
// //           variant="outlined"
// //           sx={{
// //             "& .MuiOutlinedInput-root": {
// //               borderRadius: "8px",
// //             },
// //           }}
// //         />
// //       </Box>

// //       <FormControlLabel
// //         control={<Checkbox defaultChecked />}
// //         label={
// //           <Typography variant="body2">
// //             I Agree to Terms and Conditions{" "}
// //             <Link href="#" underline="hover">
// //               T&C's Privacy Policy
// //             </Link>
// //           </Typography>
// //         }
// //       />

// //       <Button
// //         fullWidth
// //         variant="contained"
// //         sx={{
// //           backgroundColor: "#007BFF",
// //           color: "#fff",
// //           borderRadius: "8px",
// //           padding: "10px",
// //           textTransform: "none",
// //           fontWeight: "bold",
// //           mb: 2,
// //           "&:hover": {
// //             backgroundColor: "#0056b3",
// //           },
// //         }}
// //       >
// //         Login with OTP
// //       </Button>

// //       <Divider sx={{ mb: 2 }}>Or Login Using</Divider>

// //       <Button
// //         fullWidth
// //         variant="outlined"
// //         startIcon={<GoogleIcon />}
// //         sx={{
// //           borderRadius: "8px",
// //           color: "#555",
// //           textTransform: "none",
// //           padding: "10px",
// //           fontWeight: "bold",
// //         }}
// //       >
// //         Google
// //       </Button>

// //       <Typography align="center" sx={{ mt: 2 }}>
// //         <Link href="#" underline="none">
// //           Skip
// //         </Link>
// //       </Typography>
// //     </Box>
// //   );
// // };

// // export default LoginPage;
