// src/redux/actions/associateActions.js
import axios from "axios";
import {
  SEARCH_BUSSINESS_REQUEST,
  SEARCH_BUSSINESS_SUCCESS,
  SEARCH_BUSSINESS_FAILED,
  SEARCH_BUSSINESS_LOCATION,
  GET_BUSSINESS_DETAILS_REQUEST,
  GET_BUSSINESS_DETAILS_SUCCESS,
  GET_BUSSINESS_DETAILS_FAILURE,
  GETMORE_BUSSINESS_SUCCESS,
  SEARCH_BUSSINESS_PINCODE,
} from "./searchBussinessConstants";

export const searchBussiness = (data) => async (dispatch) => {
  try {
    dispatch({ type: SEARCH_BUSSINESS_REQUEST });
    const response = await axios.get(
      // `https://backend-janta-rides.vercel.app/api/businesses?category=${data?.searchBuss}&mobileNumber=&area=&landmark=&city=${data?.area}&contactPerson=`
      `https://backend-janta-rides.vercel.app/api/businesses?category=${data?.searchBuss}&mobileNumber=&area=&landmark=&city=${data?.area}&contactPerson=&pincode=${data.pincode}&offset=${data.offsetData}&limit=10`
    );

    // console.log("reponse", response);
    dispatch({ type: SEARCH_BUSSINESS_SUCCESS, payload: response.data });
    return response.data;
  } catch (error) {
    dispatch({
      type: SEARCH_BUSSINESS_FAILED,
      payload: error.response?.data.message || "Error fetching bussiness Data",
    });
  }
};
export const setSearchBussinessLocation = (location) => async (dispatch) => {
  dispatch({ type: SEARCH_BUSSINESS_LOCATION, payload: location });
};
export const setSearchBussinessPincode = (pincode) => async (dispatch) => {
  dispatch({ type: SEARCH_BUSSINESS_PINCODE, payload: pincode });
};

export const getBussinessDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: GET_BUSSINESS_DETAILS_REQUEST });
    const response = await axios.get(
      // `https://backend-janta-rides.vercel.app/api/businesses/${id}`
      `https://backend-janta-rides.vercel.app/api/businesses/${id}`
    );
    dispatch({ type: GET_BUSSINESS_DETAILS_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({
      type: GET_BUSSINESS_DETAILS_FAILURE,
      payload: error.response?.data.message || "Error fetching bussiness Data",
    });
  }
};
export const updateReduxDataBussiness = (data) => async (dispatch) => {
  try {
    // console.log("update redux data", data);
    dispatch({ type: GETMORE_BUSSINESS_SUCCESS, payload: data });
  } catch (error) {
    console.log("Error", error);
  }
};
