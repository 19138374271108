import React, { useState } from "react";
import {
  Box,
  Grid,
  Typography,
  TextField,
  Button,
  Container,
  Paper,
  IconButton,
  useTheme,
  useMediaQuery,
  FormControl,
} from "@mui/material";

import axios from "axios";
import { Facebook, LinkedIn, Twitter } from "@mui/icons-material";
import Header from "../components/Header";
import Footer from "../components/Footer";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    location: "",
    mobile: "",
  });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios
        .post(``, {
          name: formData?.name,
          mobileNumber: formData?.mobile,
          // business: null,

          location: formData?.location,

          //           business
          // :
          // "673d902048e5f05b1aa4cc30"
          // location
          // :
          // "Noida"
          // mobileNumber
          // :
          // "9616850761"
          // name
          // :
          // "Pushpendra Singh"
          // shiftOption
          // :
          // "Within City"
        })
        .then((res) => {
          console.log("reds", res.data);
        })
        .catch((err) => console.log(err));

      alert("We've received your query and will get back to you shortly.");
      setFormData({
        name: "",
        email: "",
        mobile: "",
        description: "",
      });
    } catch (error) {
      // Show error toast or alert
      alert(
        "There was an error submitting your Query. Please try again later."
      );
    }
  };

  return (
    <>
      <Header />

      <Box color="white">
        {/* Google Map Section */}
        <Box width="100%" height="400px">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m16!1m11!1m3!1d437.10398327821594!2d77.28292079577798!3d28.502109385290275!2m2!1f0!2f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2s!5e1!3m2!1sen!2sin!4v1734280026745!5m2!1sen!2sin"
            // src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d224345.83972741933!2d76.73121866536642!3d28.459496815668325!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d18d11f54fe91%3A0x1dbe40fd6aa6f414!2sFixiGo%20-%20Delhi%20Automobiles!5e0!3m2!1sen!2sin!4v1694603019751!5m2!1sen!2sin"
            width="100%"
            height="100%"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          />

          {/* <iframe
            src="https://www.google.com/maps/embed?pb=!1m16!1m11!1m3!1d437.10398327821594!2d77.28292079577798!3d28.502109385290275!2m2!1f0!2f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2s!5e1!3m2!1sen!2sin!4v1734280026745!5m2!1sen!2sin"
            width="100%"
            height="100%"
            style="border:0;"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe> */}
        </Box>

        <Container maxWidth="lg" sx={{ py: 10 }}>
          <Grid container spacing={4} alignItems="flex-start">
            <Grid item xs={12} md={5}>
              <Paper
                elevation={6}
                sx={{
                  background: "linear-gradient(to bottom, #000D6B, #1A237E)",
                  p: 4,
                  borderRadius: 2,
                  color: "white",
                }}
              >
                <Typography variant="h5" fontWeight="bold" gutterBottom>
                  Quick Contact
                </Typography>
                <Typography variant="body2" gutterBottom>
                  Have questions? Reach out to us using the details below.
                </Typography>

                <Typography variant="body1" gutterBottom>
                  <strong>Office Location:</strong> LKV 426, LEFT SIDE LALKUAN,
                  MB ROAD, NEW DELHI, 110044,
                </Typography>

                <Typography variant="body1" gutterBottom>
                  <strong>Contact Us:</strong> jantarides@gmail.com
                </Typography>

                <Box mt={3}>
                  <IconButton
                    href="#"
                    sx={{
                      backgroundColor: "white",
                      color: "#000D6B",
                      mr: 2,
                      "&:hover": {
                        backgroundColor: "#e3f2fd",
                        transform: "scale(1.05)",
                      },
                      transition: "all 0.3s ease",
                    }}
                  >
                    <Facebook size={24} />
                  </IconButton>
                  <IconButton
                    href="#"
                    sx={{
                      backgroundColor: "white",
                      color: "#000D6B",
                      mr: 2,
                      "&:hover": {
                        backgroundColor: "#e3f2fd",
                        transform: "scale(1.05)",
                      },
                      transition: "all 0.3s ease",
                    }}
                  >
                    <Twitter size={24} />
                  </IconButton>
                  <IconButton
                    href="#"
                    sx={{
                      backgroundColor: "white",
                      color: "#000D6B",
                      mr: 2,
                      "&:hover": {
                        backgroundColor: "#e3f2fd",
                        transform: "scale(1.05)",
                      },
                      transition: "all 0.3s ease",
                    }}
                  >
                    <LinkedIn size={24} />
                  </IconButton>
                </Box>
              </Paper>
            </Grid>

            <Grid item xs={12} md={7}>
              <Paper elevation={6} sx={{ p: 4, borderRadius: 2 }}>
                <Typography
                  variant="h5"
                  fontWeight="bold"
                  color="black"
                  gutterBottom
                >
                  Send Us a Query
                </Typography>
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <TextField
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                          placeholder="Your Name"
                          variant="outlined"
                          fullWidth
                          required
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <TextField
                          name="location"
                          value={formData.location}
                          onChange={handleChange}
                          placeholder="Your Location Address with picode"
                          variant="outlined"
                          fullWidth
                          required
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <TextField
                          name="mobile"
                          value={formData.mobile}
                          onChange={handleChange}
                          placeholder="Your Mobile"
                          variant="outlined"
                          fullWidth
                          required
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <TextField
                          name="description"
                          value={formData.description}
                          onChange={handleChange}
                          placeholder="Your description..."
                          variant="outlined"
                          fullWidth
                          multiline
                          rows={4}
                          required
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{
                      mt: 3,
                      py: 2,
                      background: "linear-gradient(to right, #e53935, #d32f2f)",
                      "&:hover": {
                        background:
                          "linear-gradient(to right, #ef5350, #e57373)",
                      },
                      fontSize: "1.2rem",
                    }}
                  >
                    Submit
                  </Button>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Footer />
    </>
  );
};

export default Contact;
