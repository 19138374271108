import React from "react";
import Mainroutes from "./pages/Mainroutes";
import SignInButton from "./404.webp";
const App = () => {
  // return(
     
 
 
  //   <div  >
  //     <img   src={SignInButton} alt="404" />
  //     <h3>ooops! this page can't be found.</h3>
  //     <p>It's looks like nothing was found at this location.</p>
  //     <p>It's looks like service provider not avilaable</p>
       
  //   </div>
 
  // )
  return <Mainroutes />;
};

export default App;
