import React, { useEffect, useState } from "react";
import { Grid, useMediaQuery } from "@mui/material";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./styles.css";
import axios from "axios";
const PromotionalBanner = () => {
  const isMobileOrTablet = useMediaQuery("(max-width:768px)");
  const [banners, setBanners] = useState([]);
  const fetchMoreData = () => {
    axios
      .get(`https://backend-janta-rides.vercel.app/api/banners/`)
      .then((res) => {
        setBanners(res.data);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    if (banners.length == 0) {
      fetchMoreData();
    }
  }, []);

  const homepage1Banners = banners?.filter(
    (banner) => banner?.category === "Homepage1"
  );
  const homepage2Banners = banners?.filter(
    (banner) => banner?.category === "Homepage2"
  );
  const homepage3Banners = banners?.filter(
    (banner) => banner?.category === "Homepage3"
  );

  return (
    <Grid
      container
      spacing={2}
      style={{ marginTop: 1, backgroundColor: "#FBFCFF" }}
    >
      {/* First Grid Item */}
      <Grid
        item
        xs={12}
        md={4}
        style={{
          display: isMobileOrTablet ? "block" : "block", // Always visible
        }}
      >
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={false}
          modules={[Autoplay, Pagination, Navigation]}
          className="mySwiper"
        >
          {homepage1Banners?.map((item, index) => {
            const slideStyle = {
              height: isMobileOrTablet ? "auto" : "400px",
            };
            const imgStyle = {
              width: "100%",
              objectFit: "contain",
            };

            return (
              <SwiperSlide key={index} style={slideStyle}>
                <img
                  src={item?.imagePath}
                  alt={index}
                  style={imgStyle}
                  sizes="banner"
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Grid>

      {/* Second Grid Item */}
      <Grid
        item
        xs={12}
        md={4}
        style={{
          display: isMobileOrTablet ? "none" : "block", // Hidden on mobile/tablet
        }}
      >
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={false}
          modules={[Autoplay, Pagination, Navigation]}
          className="mySwiper"
        >
          {homepage2Banners?.map((item, index) => {
            const slideStyle = {
              height: isMobileOrTablet ? "auto" : "400px",
            };
            const imgStyle = {
              width: "100%",
              objectFit: "contain",
            };

            return (
              <SwiperSlide key={index} style={slideStyle}>
                <img
                  src={item?.imagePath}
                  alt={index}
                  style={imgStyle}
                  sizes="banner"
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Grid>

      {/* Third Grid Item */}
      <Grid
        item
        xs={12}
        md={4}
        style={{
          display: isMobileOrTablet ? "none" : "block", // Hidden on mobile/tablet
        }}
      >
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={false}
          modules={[Autoplay, Pagination, Navigation]}
          className="mySwiper"
        >
          {homepage3Banners?.map((item, index) => {
            const slideStyle = {
              height: isMobileOrTablet ? "auto" : "400px",
            };
            const imgStyle = {
              width: "100%",
              objectFit: "contain",
            };

            return (
              <SwiperSlide key={index} style={slideStyle}>
                <img
                  src={item?.imagePath}
                  alt={index}
                  style={imgStyle}
                  sizes="banner"
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Grid>
    </Grid>
  );
};
// <Grid container spacing={2} style={{ marginTop: 1 }}>
//   <Grid item xs={12} md={4} style={{ border: "1px solid red" }}>
//     <Swiper
//       spaceBetween={30}
//       centeredSlides={true}
//       autoplay={{
//         delay: 2500,
//         disableOnInteraction: false,
//       }}
//       pagination={{
//         clickable: true,
//       }}
//       navigation={false}
//       modules={[Autoplay, Pagination, Navigation]}
//       className="mySwiper"
//     >
//       {banners?.map((item, index) => {
//         // Inline styles for img and SwiperSlide
//         const isMobile = window.innerWidth <= 768;
//         const slideStyle = {
//           height: isMobile ? "auto" : "400px", // Change height based on screen size
//         };
//         const imgStyle = {
//           width: "100%",
//           objectFit: "contain", // Ensure the image covers the space without distortion
//         };

//         return (
//           <SwiperSlide
//             key={index + item + "12+" + index}
//             style={slideStyle}
//           >
//             <img
//               src={item?.imagePath}
//               // src="https://dummyimage.com/1600x400/000/fff"
//               alt={index}
//               style={imgStyle}
//               sizes="banner"
//             />
//           </SwiperSlide>
//         );
//       })}
//     </Swiper>
//   </Grid>
//   <Grid item xs={12} md={4} style={{ border: "1px solid red" }}>
//     <Swiper
//       spaceBetween={30}
//       centeredSlides={true}
//       autoplay={{
//         delay: 2500,
//         disableOnInteraction: false,
//       }}
//       pagination={{
//         clickable: true,
//       }}
//       navigation={false}
//       modules={[Autoplay, Pagination, Navigation]}
//       className="mySwiper"
//     >
//       {banners?.map((item, index) => {
//         // Inline styles for img and SwiperSlide
//         const isMobile = window.innerWidth <= 768;
//         const slideStyle = {
//           height: isMobile ? "auto" : "400px", // Change height based on screen size
//         };
//         const imgStyle = {
//           width: "100%",
//           objectFit: "contain", // Ensure the image covers the space without distortion
//         };

//         return (
//           <SwiperSlide
//             key={index + item + "12+" + index}
//             style={slideStyle}
//           >
//             <img
//               src={item?.imagePath}
//               // src="https://dummyimage.com/1600x400/000/fff"
//               alt={index}
//               style={imgStyle}
//               sizes="banner"
//             />
//           </SwiperSlide>
//         );
//       })}
//     </Swiper>
//   </Grid>
//   <Grid item xs={12} md={4} style={{ border: "1px solid red" }}>
//     <Swiper
//       spaceBetween={30}
//       centeredSlides={true}
//       autoplay={{
//         delay: 2500,
//         disableOnInteraction: false,
//       }}
//       pagination={{
//         clickable: true,
//       }}
//       navigation={false}
//       modules={[Autoplay, Pagination, Navigation]}
//       className="mySwiper"
//     >
//       {banners?.map((item, index) => {
//         // Inline styles for img and SwiperSlide
//         const isMobile = window.innerWidth <= 768;
//         const slideStyle = {
//           height: isMobile ? "auto" : "400px", // Change height based on screen size
//         };
//         const imgStyle = {
//           width: "100%",
//           objectFit: "contain", // Ensure the image covers the space without distortion
//         };

//         return (
//           <SwiperSlide
//             key={index + item + "12+" + index}
//             style={slideStyle}
//           >
//             <img
//               src={item?.imagePath}
//               // src="https://dummyimage.com/1600x400/000/fff"
//               alt={index}
//               style={imgStyle}
//               sizes="banner"
//             />
//           </SwiperSlide>
//         );
//       })}
//     </Swiper>
//   </Grid>
// </Grid>

export default PromotionalBanner;
