import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";

function EnquiryFormDialog({ open, handleClose, business }) {
  const { hash, pathname } = useLocation();
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = async (data) => {
    await axios
      .post(`https://backend-janta-rides.vercel.app/api/user-enquiry/create`, {
        ...data,
        business: business?._id,
      })
      .then((res) => {
        // console.log("reds", res.data);
      })
      .catch((err) => console.log(err));

    handleClose();
    reset();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="body1" fontWeight="bold">
            {business?.businessName ?? "Get the List of Top Packers And Movers"}
          </Typography>
          <Button onClick={handleClose}>&times;</Button>
        </Box>
      </DialogTitle>

      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: "space-between",
              gap: { xs: 2, sm: 4 },
            }}
          >
            {/* Left Side - Form */}
            <Box
              sx={{
                width: { xs: "100%", sm: "60%" },
                paddingRight: { sm: 2 },
              }}
            >
              {/* Name Field */}
              <Controller
                name="name"
                control={control}
                defaultValue=""
                rules={{ required: "Name is required" }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Name*"
                    variant="outlined"
                    fullWidth
                    sx={{ mb: 2 }}
                    error={!!errors.name}
                    helperText={errors.name ? errors.name.message : ""}
                  />
                )}
              />

              {/* Mobile Number Field */}
              <Controller
                name="mobileNumber"
                control={control}
                defaultValue=""
                rules={{
                  required: "Mobile number is required",
                  pattern: {
                    value: /^[0-9]{10}$/,
                    message: "Enter a valid 10-digit mobile number",
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Mobile Number*"
                    variant="outlined"
                    fullWidth
                    sx={{ mb: 2 }}
                    error={!!errors.mobileNumber}
                    helperText={
                      errors.mobileNumber ? errors.mobileNumber.message : ""
                    }
                  />
                )}
              />

              {/* Location Field */}
              <Controller
                name="location"
                control={control}
                defaultValue=""
                rules={{ required: "Location is required" }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Location*"
                    variant="outlined"
                    fullWidth
                    sx={{ mb: 2 }}
                    error={!!errors.location}
                    helperText={errors.location ? errors.location.message : ""}
                  />
                )}
              />

              {/* Shift Option Radio Group */}
              <FormLabel component="legend" sx={{ mb: 1 }}>
                Where do you want to shift?
              </FormLabel>
              <Controller
                name="shiftOption"
                control={control}
                defaultValue="Within City"
                render={({ field }) => (
                  <RadioGroup {...field} row sx={{ mb: 3 }}>
                    <FormControlLabel
                      value="Within City"
                      control={<Radio />}
                      label="Within City"
                    />
                    <FormControlLabel
                      value="Outside City"
                      control={<Radio />}
                      label="Outside City"
                    />
                  </RadioGroup>
                )}
              />

              {/* Submit Button */}
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                sx={{
                  backgroundColor: "#007BFF",
                  color: "#FFFFFF",
                  "&:hover": {
                    backgroundColor: "#0056b3",
                  },
                  mb: 3,
                }}
              >
                Send Enquiry
              </Button>

              <Typography variant="body2" color="textSecondary">
                <ul>
                  <li>
                    Your requirement is sent to the selected relevant businesses
                  </li>
                  <li>
                    Businesses compete with each other to get you the Best Deal
                  </li>
                  <li>You choose whichever suits you best</li>
                  <li>Contact info sent to you by SMS/Email</li>
                </ul>
              </Typography>
            </Box>

            {/* Right Side - Ad/Info Section */}
            <Box
              sx={{
                width: { xs: "100%", sm: "35%" },
                textAlign: "center",
                backgroundColor: "#f9f9f9",
                padding: 2,
                borderRadius: 2,
              }}
            >
              <Typography variant="caption" color="primary" sx={{ mb: 1 }}>
                Create & Manage your Business for FREE
              </Typography>
              <Typography variant="body2" color="textSecondary" sx={{ mb: 3 }}>
                Reach 150+ Million Users
              </Typography>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  backgroundColor: "#007BFF",
                  color: "#FFFFFF",
                  "&:hover": {
                    backgroundColor: "#0056b3",
                  },
                }}
                onClick={() => {
                  navigate(pathname);
                  handleClose();
                  reset();
                }}
              >
                Go to My Business
              </Button>
            </Box>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
}

// <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
//   <DialogTitle>
//     <Box display="flex" justifyContent="space-between" alignItems="center">
//       <Typography variant="body2">
//         Get the List of Top Packers And Movers
//       </Typography>
//       <Button onClick={handleClose}>&times;</Button>
//     </Box>
//   </DialogTitle>

//   <DialogContent>
//     <Box
//       sx={{
//         display: "flex",
//         flexDirection: { xs: "column", sm: "row" }, // Stack on small screens, row on larger screens
//         justifyContent: "space-between",
//         gap: { xs: 2, sm: 4 }, // Add space between items
//       }}
//     >
//       {/* Left Side - Form */}
//       <Box
//         sx={{
//           width: { xs: "100%", sm: "60%" }, // Full width on small screens, 60% on larger
//           paddingRight: { sm: 2 }, // Padding only for larger screens
//         }}
//       >
//         <TextField
//           label="Name*"
//           variant="outlined"
//           fullWidth
//           sx={{ mb: 2 }}
//         />
//         <TextField
//           label="Mobile Number*"
//           variant="outlined"
//           fullWidth
//           sx={{ mb: 2 }}
//         />

//         <FormLabel component="legend" sx={{ mb: 1 }}>
//           Where do you want to shift?
//         </FormLabel>
//         <TextField
//           label="Location*"
//           variant="outlined"
//           fullWidth
//           sx={{ mb: 2 }}
//         />
//         <RadioGroup
//           row
//           value={shiftOption}
//           onChange={handleOptionChange}
//           sx={{ mb: 3 }}
//         >
//           <FormControlLabel
//             value="Within City"
//             control={<Radio />}
//             label="Within City"
//           />
//           <FormControlLabel
//             value="Outside City"
//             control={<Radio />}
//             label="Outside City"
//           />
//         </RadioGroup>

//         <Button
//           variant="contained"
//           color="primary"
//           fullWidth
//           sx={{
//             backgroundColor: "#007BFF",
//             color: "#FFFFFF",
//             "&:hover": {
//               backgroundColor: "#0056b3",
//             },
//             mb: 3,
//           }}
//         >
//           Send Enquiry
//         </Button>

//         <Typography variant="body2" color="textSecondary">
//           <ul>
//             <li>
//               Your requirement is sent to the selected relevant businesses
//             </li>
//             <li>
//               Businesses compete with each other to get you the Best Deal
//             </li>
//             <li>You choose whichever suits you best</li>
//             <li>Contact info sent to you by SMS/Email</li>
//           </ul>
//         </Typography>
//       </Box>

//       {/* Right Side - Ad/Info Section */}
//       <Box
//         sx={{
//           width: { xs: "100%", sm: "35%" }, // Full width on small screens, 35% on larger
//           textAlign: "center",
//           backgroundColor: "#f9f9f9",
//           padding: 2,
//           borderRadius: 2,
//         }}
//       >
//         <Typography variant="caption" color="primary" sx={{ mb: 1 }}>
//           Create & Manage your Business for FREE
//         </Typography>
//         <Typography variant="body2" color="textSecondary" sx={{ mb: 3 }}>
//           Reach 150+ Million Users
//         </Typography>
//         <Button
//           variant="contained"
//           color="primary"
//           sx={{
//             backgroundColor: "#007BFF",
//             color: "#FFFFFF",
//             "&:hover": {
//               backgroundColor: "#0056b3",
//             },
//           }}
//         >
//           Go to My Business
//         </Button>
//       </Box>
//     </Box>
//   </DialogContent>
// </Dialog>

export default EnquiryFormDialog;
