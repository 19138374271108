import React, { useEffect, useState } from "react";
import ListHeader from "../components/ListHeader";
import ListPagePromotionalBanner from "../components/ListPagePromotionalBanner";
import ListPageHeader from "../components/ListPageHeader";
import BusinessCard from "../components/BussinessCard";
import Footer from "../components/Footer";
import SearchSection from "../components/SearchSection";
import { useDispatch, useSelector } from "react-redux";
import {
  searchBussiness,
  updateReduxDataBussiness,
} from "../redux/searchBussiness/searchBussinessActions";
import { useParams } from "react-router-dom";
import { Box, CircularProgress, Typography } from "@mui/material";
import Header from "../components/Header";
import nodatafound from "./search.png";
import InfiniteScroll from "react-infinite-scroll-component";
import axios from "axios";
const ListsPage = () => {
  const [hasMore, setHasMore] = useState(true);
  const [index, setIndex] = useState(0);
  const [initialized, setInitialized] = useState(false); // Track initial load
  const {
    location: userLocation,
    data,
    isLoading,
    totalCount,
    pincode,
    offsetData,
  } = useSelector((state) => state.bussinessSearch);
  const { id } = useParams();
  const dispatch = useDispatch();
  // const fetchMoreData = () => {
  //   // if (!hasMore) return;
  //   console.log("object1");
  //   axios
  //     .get(
  //       `https://backend-janta-rides.vercel.app/api/businesses?category=${id}&mobileNumber=&area=&landmark=&city=${userLocation}&contactPerson=&pincode=${pincode}&offset=${
  //         index * 10
  //       }&limit=10`
  //     )
  //     .then((res) => {
  //       console.log("object2", res);
  //       dispatch(updateReduxDataBussiness([...data, ...res?.data?.results]));

  //       // dispatch(updateReduxDataBussiness([...data, ...res?.data]));
  //       res?.data?.length > 0 ? setHasMore(true) : setHasMore(false);
  //     })
  //     .catch((err) => console.log(err));
  //   console.log("object3");
  //   setIndex((prevIndex) => prevIndex + 1);
  // };

  const fetchMoreData = () => {
    axios
      .get(
        `https://backend-janta-rides.vercel.app/api/businesses?category=${id}&mobileNumber=&area=&landmark=&city=${userLocation}&contactPerson=&pincode=${pincode}&offset=${
          index * 10
        }&limit=10`
      )
      .then((res) => {
        const apiData = Array.isArray(res?.data?.results)
          ? res.data?.results
          : [];
        const reduxData = Array.isArray(data) ? data : [];

        // console.log("Appending new data:", apiData.length, "items");
        dispatch(updateReduxDataBussiness([...reduxData, ...apiData]));
        res?.data?.results?.length > 0 ? setHasMore(true) : setHasMore(false);
      })
      .catch((err) => console.error("Error loading data:", err));
    setIndex((prevIndex) => prevIndex + 1);
  };

  return (
    <>
      <Header />
      <SearchSection />
      <ListPagePromotionalBanner />
      <ListPageHeader data={data} />
      <InfiniteScroll
        dataLength={data?.length || 0}
        next={fetchMoreData}
        hasMore={hasMore}
        loader={isLoading && <h1>Loading</h1>}
      >
        {isLoading ? (
          <Box
            sx={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 1301,
            }}
          >
            <CircularProgress />
          </Box>
        ) : data?.length === 0 ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              padding: { xs: "10px", sm: "20px" },
              margin: "auto",
            }}
          >
            <img
              src={nodatafound}
              alt="No Data Found"
              style={{
                maxWidth: "100%",
                height: "auto",
                width: { xs: "80%", sm: "60%", md: "40%" },
              }}
            />
          </Box>
        ) : (
          <div className="container">
            <div className="row">
              {data &&
                data?.map((item) => (
                  <BusinessCard key={item?._id} data={item} />
                ))}
            </div>
          </div>
        )}
      </InfiniteScroll>
      <Footer />
    </>
  );
};

export default ListsPage;
