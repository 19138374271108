import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import Header from "../components/Header";
import FAQ from "../components/Faq";
import Footer from "../components/Footer";

const services = [
  {
    title: "Driver App Login",
    description:
      "Drivers need secure access to the app to view ride requests and manage their schedules.",
  },
  {
    title: "Real-Time Tracking",
    description:
      "Allows users to track the location of their booked vehicle in real-time.",
  },
  {
    title: "Real-Time Car Details Fetching",
    description:
      "Users can view real-time details of the vehicle assigned to their ride.",
  },
  {
    title: "Review Features",
    description: "Users can rate and review drivers after completing a ride.",
  },
  {
    title: "Referral Code",
    description:
      "Users can refer the app to others using a unique referral code, earning rewards.",
  },
  {
    title: "Penalty on Drink and Drive",
    description:
      "Penalize drivers caught under the influence of alcohol during duty.",
  },
  {
    title: "GST 12% on Each Ride",
    description:
      "Automatically calculate and apply GST on every completed ride fare.",
  },
  {
    title: "Sharing and Personal Booking",
    description:
      "Users can book rides for themselves or share rides with others.",
  },
  {
    title: "Vendor Can Add Multiple Vehicles",
    description:
      "Vendors (fleet owners) can add multiple vehicles to the platform.",
  },
  {
    title: "Commission for Vehicle Additions",
    description: "Reward the vendor who adds a vehicle when it gets a ride.",
  },
  {
    title: "Tree-Like Structure",
    description:
      "Organize data in a hierarchical tree structure for efficient management.",
  },
  {
    title: "Night Charges (5% After 12 AM)",
    description: "Apply surcharges for rides booked after midnight.",
  },
];

const AboutUs = () => {
  return (
    <>
      <Header />

      <Box
        sx={{
          position: "relative",
          //   background: "linear-gradient(to right, #4a00e0, #8e2de2)", // Gradient background
          //   color: "#fff",
          p: 5,
          textAlign: "center",
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box sx={{ maxWidth: "800px" }}>
          <Typography
            variant="h3"
            sx={{ fontWeight: "bold", mb: 3, lineHeight: 1.2 }}
          >
            About Janta Rides
          </Typography>
          <Typography variant="h6" sx={{ mb: 4 }}>
            Jant Rides is dedicated to providing the best ride experience across
            India. Whether you're looking for luxury, convenience, or a quick
            trip, we've got you covered! Our services span from city rides to
            long-distance travel, ensuring safety, comfort, and satisfaction
            every step of the way.
          </Typography>
          <Divider sx={{ mb: 4 }} />
          <Typography variant="h5" sx={{ mb: 3 }}>
            Our Key Features:
          </Typography>
          <Grid container spacing={3}>
            {services.map((service, index) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                key={index + "service.title45" + service.title + index}
              >
                <Paper
                  sx={{
                    p: 1,
                    m: 1,
                    height: "100%",
                  }}
                >
                  <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
                    {service.title}
                  </Typography>
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    {service.description}
                  </Typography>
                </Paper>
              </Grid>
            ))}
          </Grid>
          <Divider sx={{ mt: 4, mb: 4, bgcolor: "#fff" }} />
          {/* <Button
            variant="contained"
            sx={{
              backgroundColor: "#ff4081",
              //   color: "#fff",
              fontWeight: "bold",
              padding: "12px 24px",
              fontSize: "1rem",
              "&:hover": {
                backgroundColor: "#f50057",
              },
            }}
          >
            Learn More About Us
          </Button> */}
        </Box>
      </Box>
      <FAQ />
      <Footer />
    </>
  );
};

export default AboutUs;
