// src/redux/actions/authActions.js
import axios from "axios";
import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  LOAD_USER,
  AUTH_ERROR,
} from "./actionTypes";

// Load User
export const loadUser = () => async (dispatch) => {
  // Check if webtoken exists
  const webtoken = localStorage.getItem("webtoken");
  if (webtoken) {
    // Set webtoken in headers
    axios.defaults.headers.common["Authorization"] = `Bearer ${webtoken}`;
  } else {
    dispatch({
      type: AUTH_ERROR,
    });
    return;
  }

  try {
    const res = await axios.get(
      "https://backend-janta-rides.vercel.app/api/auth/me"
    );
    dispatch({
      type: LOAD_USER,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

// Login User
export const login = (data) => async (dispatch) => {
  try {
    dispatch({
      type: LOGIN_SUCCESS,
      payload: data,
    });

    // dispatch(loadUser());
  } catch (err) {
    dispatch({
      type: LOGIN_FAIL,
      payload: err.response.data.message,
    });
  }
};

// Logout User
export const logout = () => (dispatch) => {
  // Remove webtoken from local storage and axios headers
  localStorage.removeItem("webtoken");
  delete axios.defaults.headers.common["Authorization"];
  dispatch({ type: LOGOUT });
};
