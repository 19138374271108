import React, { useEffect, useState } from "react";
import {
  TextField,
  InputAdornment,
  IconButton,
  Box,
  Autocomplete,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  searchBussiness,
  setSearchBussinessLocation,
  setSearchBussinessPincode,
} from "../redux/searchBussiness/searchBussinessActions";
import { categories, statelist } from "../constants";
import SearchLocationInput from "./GooglePlcasesApi";

const SearchSection = () => {
  const {
    location: userLocation,
    offsetData,
    pincode,
  } = useSelector((state) => state.bussinessSearch);

  const [area, setArea] = useState("");
  const [searchBuss, setSearchBuss] = useState("");
  const [searchPincode, setSearchPincode] = useState(pincode ?? "");

  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (userLocation && statelist.includes(userLocation)) {
      setArea(userLocation);
    } else {
      setArea(""); // Reset area if userLocation is invalid
    }
  }, [userLocation]);
  return (
    <Box
      // sx={{
      //   display: "flex",
      //   justifyContent: { xs: "flex-start", sm: "center" },
      //   alignItems: "center",
      //   flexDirection: { xs: "column", sm: "row" },
      //   gap: { xs: 2, sm: 1 },
      //   px: { xs: 2, sm: 4 },
      //   pt: 2,
      // }}
      sx={{
        display: "flex",
        justifyContent: { xs: "flex-start", sm: "center" },
        alignItems: "center",
        flexDirection: { xs: "column", sm: "row" },
        gap: { xs: 2, sm: 2 },
        px: { xs: 2, sm: 4 },
        pt: 2,
        backgroundColor: "#FBFCFF",
      }}
    >
      <SearchLocationInput setArea={setArea} area={area} />
      {/* <TextField
        variant="outlined"
        placeholder="Search Pincode"
        onChange={(e) => setSearchPincode(e.target.value)}
        sx={{
          width: { xs: "100%", sm: "auto" },
          mr: { sm: 2 },
          display: { md: "block" },
        }}
      />

      <Autocomplete
        sx={{ width: "100%" }}
        freeSolo
        disablePortal
        options={categories.map((option) => option.name)}
        onChange={(event, newValue) => {
          setSearchBuss(newValue || "");
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder="Search for Car & Pickup..."
            onChange={(e) => setSearchBuss(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault(); // Prevent the default form submission behavior
                dispatch(
                  searchBussiness({
                    searchBuss,
                    area,
                    offsetData,
                  })
                );
                navigate(`/listings/${searchBuss}`);
              }
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => {
                      dispatch(
                        searchBussiness({
                          searchBuss,
                          area: userLocation,
                          offsetData,
                        })
                      );
                      navigate(`/listings/${searchBuss}`);
                    }}
                  >
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{
              flex: 1,
              width: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "100%",
                xl: "100%",
              },
            }}
          />
        )}
      /> */}

      <TextField
        size="small"
        variant="outlined"
        placeholder="Search Pincode"
        defaultValue={pincode ?? ""}
        onChange={(e) => {
          setSearchPincode(e.target.value);
          dispatch(setSearchBussinessPincode(e.target.value));
        }}
        sx={{
          flex: 1,
          width: "100%",
          maxWidth: { xs: "97%", sm: "200px" }, // Reduce max width for different screen sizes
        }}
      />

      {/* Autocomplete Input */}
      <Autocomplete
        sx={{
          flex: 1,
          width: "100%",
          maxWidth: { xs: "97%", sm: "300px" },
        }}
        freeSolo
        disablePortal
        options={categories.map((option) => option.name)}
        onChange={(event, newValue) => {
          setSearchBuss(newValue || "");
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            variant="outlined"
            placeholder="Search for Car & Pickup..."
            onChange={(e) => setSearchBuss(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault(); // Prevent the default form submission behavior
                dispatch(
                  searchBussiness({
                    searchBuss,
                    area: userLocation,
                    offsetData,
                    pincode: searchPincode ?? pincode,
                  })
                );
                navigate(`/listings/${searchBuss}`);
              }
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => {
                      dispatch(
                        searchBussiness({
                          searchBuss,
                          area: userLocation,
                          offsetData,
                          pincode: searchPincode ?? pincode,
                        })
                      );
                      navigate(`/listings/${searchBuss}`);
                    }}
                  >
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </Box>
  );
};

export default SearchSection;
