import React, { useEffect } from "react";
import Homepage from "./Homepage";
import ListsPage from "./ListsPage";
import DetailsPage from "./DetailsPage";
import { Route, Routes } from "react-router-dom";
import LoginPage from "./Loginpage";
import AboutUs from "./About";
import PrivacyPolicy from "./Privacy";
import Term from "./Terms";
import Contact from "./Contact";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setSearchBussinessLocation } from "../redux/searchBussiness/searchBussinessActions";
import Auth from "../components/Auth";
import WriteReview from "../components/Review";
import NewLogin from "./NewLogin";
import LoginDialog from "./LoginDialog";
import ReviewDialog from "./ReviewDialog";

const Mainroutes = () => {
  const dispatch = useDispatch();

  // useEffect(() => {
  //   if ("geolocation" in navigator) {
  //     navigator.geolocation.getCurrentPosition(
  //       (position) => {
  //         const latitude = position.coords.latitude;
  //         const longitude = position.coords.longitude;

  //         // Use Nominatim for reverse geocoding
  //         const apiUrl = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&addressdetails=1`;

  //         axios
  //           .get(apiUrl)
  //           .then((response) => {
  //             if (response.data && response.data.address) {
  //               // console.log("address", response.data);
  //               // dispatch(
  //               //   setSearchBussinessLocation(
  //               //     response.data.address.state || ""
  //               //     // response.data.address.town ||
  //               //     // response.data.address.village ||
  //               //   )
  //               // );
  //             } else {
  //               console.log("Unable to retrieve location details");
  //             }
  //           })
  //           .catch((err) => {
  //             console.log("Failed to fetch location details");
  //             console.error(err);
  //           });
  //       },
  //       (err) => {
  //         console.log("Unable to retrieve your location");
  //         console.error(err);
  //       }
  //     );
  //   } else {
  //     console.log("Geolocation is not supported by your browser");
  //   }
  // }, []);
  return (
    <>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/review" element={<WriteReview />} />
        <Route path="/listings/:id" element={<ListsPage />} />
        <Route path="/detailspage/:id" element={<DetailsPage />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/terms" element={<Term />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <LoginDialog />
      <ReviewDialog />
    </>
  );
};

export default Mainroutes;
